<template lang="pug">
  b-modal#modal-create-event-type(ref="modal-create-event-type", centered, :title="(eventType.id ? 'Modifier': 'Ajouter') + ` un type d'événement`", @ok="create")
    div(v-if="isCreatingEventType")
      .text-center 
        .spinner-border.text-primary(role="status")
          span.sr-only Chargement...
        br
        | Chargement des détails...
    validation-observer(v-else, ref="formCreateEventType")
        b-row
          b-col(cols="12")
            b-row
              b-col(cols="10")
                b-form-group(label="Libellé", label-for="label")
                  validation-provider(#default="{ errors }", name="Label", rules="required")
                    b-form-input#label(v-model="eventType.label", :state="errors.length > 0 ? false : null", placeholder="Renseigner un libellé")
                    small.text-danger  {{ errors[0] }}
              b-col(cols="2")
                b-form-group(label-for="color")
                  validation-provider(#default="{ errors }", name="Color", rules="required")
                    v-swatches#color(show-fallback, fallback-input-type="color", popover-x="left", :state="errors.length > 0 ? false : null", v-model="eventType.color")
                    small.text-danger {{ errors[0] }}
          b-col(cols="12")
            b-form-group(label="Description", label-for="description")
              validation-provider(#default="{ errors }", name="Description")
                b-form-textarea#description(placeholder="Renseigner une description", v-model="eventType.description", :state="errors.length > 0 ? false : null", rows="3")
                small.text-danger {{ errors[0] }}
          b-col(cols="12")
            b-form-group(label-for="isDependent")
              span Ce type d&apos;évènement est-il dépendant ?
              b-form-checkbox#isDependent.float-right(checked="false", v-model="eventType.isDependent", switch, inline)
          b-col(cols="12")
            b-form-group(label-for="isEditable")
              span Ce type d&apos;évènement est-il modifiable par un tiers ?
              b-form-checkbox#isEditable.float-right(checked="false", v-model="eventType.isEditable", switch, inline)
    template(#modal-footer="{ ok, cancel }")
      .w-100
        b-button.float-left(v-ripple.400="'rgba(255, 255, 255, 0.15)'", v-if="eventType.id" variant="outline-danger", @click="ok()") Supprimer
        b-button.float-right(v-ripple.400="'rgba(255, 255, 255, 0.15)'", variant="primary", @click="ok()") Ajouter
        b-button.float-right.mr-1(v-ripple.400="'rgba(255, 255, 255, 0.15)'", variant="outline-primary", @click="cancel()") Annuler

</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import VSwatches from "vue-swatches";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      eventType: {
        label: "",
        color: "#0c3571",
        isDependent: false,
        isEditable: false,
        description: "",
        workspaceId: this.$store.getters.workspaceSelected,
      },
    };
  },
  methods: {
    popupCreateEventTypeEvent(id){
      if(id!=''){
        this.fetchEventType(id).then(res => {
          this.eventType = res
        })
      }else this.initializeForm()
    },
    initializeForm() {
      this.eventType = {
        label: "",
        color: "#0c3571",
        isDependent: false,
        isEditable: false,
        description: "",
        workspaceId: this.$store.getters.workspaceSelected,
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreateEventType.validate().then((success1) => {
        if (success1) {
          if(this.eventType.id) this.updateEventType(this.eventType)
          else this.createEventType(this.eventType);
          this.$nextTick(() => {
            this.$refs["modal-create-event-type"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createEventType", "updateEventType", "fetchEventType"]),
  },
  computed: {
    ...mapGetters(["isCreatingEventType"]),
  },
  
  components: {
    ValidationProvider,
    ValidationObserver,
    VSwatches,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
</style>